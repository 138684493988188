import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { useGlobalDispatch } from '~/context/global';
import { useNavigate } from '~/state/hooks/useNavigate';
import { Vaccine } from '~/context/global/user/account';
import { createHouseholdVaccinationsObject } from './helpers';
import { HouseholdHealthStatus } from '~/context/global/reports/types';
import { MemberVaccinationSelectList } from './MemberVaccinationSelectList';
import {
  useHouseholdVaccinationForm,
  VaccinationFormValues,
} from './useHouseholdVaccination';
import * as Styled from './styles';

interface Props {
  location?: Location & {
    state: {
      recaptchaToken?: string;
      householdHealthStatus?: HouseholdHealthStatus;
    };
  };
}

export const HouseholdVaccination: React.FC<Props> = ({ location }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const dispatch = useGlobalDispatch();
  const {
    form,
    fluVaccineFields,
    covidVaccineFields,
    someoneIsUnvaccinated,
  } = useHouseholdVaccinationForm();

  const recaptchaToken = location?.state?.recaptchaToken;
  const householdHealthStatus = location?.state?.householdHealthStatus;

  if (!recaptchaToken) {
    navigate('', { replace: true });
    return <></>;
  }

  const reportType =
    householdHealthStatus === HouseholdHealthStatus.WELL ? 'well' : 'unwell';

  const navigateToDemographics = () => {
    navigate('household-demographics', {
      state: { recaptchaToken, householdHealthStatus },
    });
  };

  if (!someoneIsUnvaccinated) {
    navigateToDemographics();
  }

  const saveVaccinationsToStore = (values: VaccinationFormValues) => {
    const vaccinations = createHouseholdVaccinationsObject(values);

    Object.entries(vaccinations).forEach(([memberID, vaccines]) => {
      const id = parseInt(memberID);
      const isHeadOfHousehold = id === -1;

      if (isHeadOfHousehold) {
        dispatch({
          type: 'SET_HEAD_OF_HOUSEHOLD_HEALTH_REPORT',
          payload: { healthReport: vaccines },
        });
      } else {
        dispatch({
          type: 'SET_HOUSEHOLD_MEMBER_HEALTH_REPORT',
          payload: { memberID: id, memberReport: vaccines },
        });
      }
    });
  };

  const onSubmit = (values: VaccinationFormValues) => {
    saveVaccinationsToStore(values);
    navigateToDemographics();
  };

  return (
    <Styled.PageContainer>
      <Styled.Container>
        <Styled.PageTitle>
          {t(`HouseholdVaccination.pageTitle.${reportType}`)}
        </Styled.PageTitle>
      </Styled.Container>
      <Styled.Container>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {fluVaccineFields.length > 0 && (
            <MemberVaccinationSelectList
              fields={fluVaccineFields}
              formControl={form.control}
              vaccine={Vaccine.FLU}
            />
          )}

          {covidVaccineFields.length > 0 && (
            <MemberVaccinationSelectList
              fields={covidVaccineFields}
              formControl={form.control}
              vaccine={Vaccine.COVID}
            />
          )}
        </Grid>
      </Styled.Container>
      <Styled.BottomContainer>
        <Button
          color="primary"
          variant="contained"
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
          data-test="household-vaccination-next-button"
        >
          {t(`HouseholdReport.button.next`)}
        </Button>
      </Styled.BottomContainer>
    </Styled.PageContainer>
  );
};
