import { Account, Vaccinations, Vaccine } from '~/context/global/user/account';
import { VaccinationFormValues } from '../HouseholdVaccination/useHouseholdVaccination';
import {
  HealthStatus,
  HouseholdMemberHealthReport,
  HouseholdMembersHealthReport,
  HeadOfHouseholdHealthReport,
} from '~/context/global/reports/householdReport';
import {
  VaccinationValue,
  getCurrentFluSeason,
  getCovidVaccineYear,
  isVaccinatedForFlu,
  isVaccinatedForCovid,
} from '~/common/vaccinations';

type FormField = 'flu' | 'covid';

const keyMap = {
  flu: 'received_flu_vaccine',
  covid: 'received_covid_vaccine',
};

export const updateHouseholdMemberVaccinationValue = (
  memberHeathReport: HouseholdMemberHealthReport | HeadOfHouseholdHealthReport,
  virus: FormField,
  vaccinationValue: string
): void => {
  const reportType =
    memberHeathReport.healthStatus === HealthStatus.WELL
      ? 'wellReport'
      : 'unwellReport';

  if (vaccinationValue) {
    const vaccinationKeyToUpdate = keyMap[virus];
    memberHeathReport[reportType][vaccinationKeyToUpdate] = vaccinationValue;
  }

  if (virus === Vaccine.FLU) {
    memberHeathReport[reportType].flu_season = getCurrentFluSeason();
    if (!vaccinationValue) {
      memberHeathReport[reportType].received_flu_vaccine =
        VaccinationValue.UNKNOWN;
    }
  }
};

export const updateHouseholdMembersVaccinationValues = (
  headOfHouseholdHealthReport: HeadOfHouseholdHealthReport,
  householdMemberReports: HouseholdMembersHealthReport,
  formValues: VaccinationFormValues
): void => {
  Object.entries(formValues).forEach(([key, value]) => {
    const [memberID, virus] = key.split('_');
    const isHeadOfHousehold = parseInt(memberID) === -1;
    const report = isHeadOfHousehold
      ? headOfHouseholdHealthReport
      : householdMemberReports[memberID];
    const memberSkipped = report.healthStatus === HealthStatus.SKIP;

    if (!memberSkipped) {
      updateHouseholdMemberVaccinationValue(report, virus as FormField, value);
    }
  });
};

export interface HouseholdVaccinationsObject {
  [memberId: number]: {
    // eslint-disable-next-line camelcase
    received_covid_vaccine?: VaccinationValue;
    // eslint-disable-next-line camelcase
    received_flu_vaccine?: VaccinationValue;
    // eslint-disable-next-line camelcase
    flu_season?: string;
    // eslint-disable-next-line camelcase
    covid_season?: string;
  };
}

export const createHouseholdVaccinationsObject = (
  vaccinationFormValues: VaccinationFormValues
): HouseholdVaccinationsObject => {
  return Object.keys(vaccinationFormValues).reduce(
    (vaccinations: HouseholdVaccinationsObject, idAndVirus) => {
      const answer = vaccinationFormValues[idAndVirus];
      const [memberID, virus] = idAndVirus.split('_');
      const id = parseInt(memberID);

      if (!vaccinations[id]) {
        vaccinations[id] = {};
      }

      if (answer) {
        const vaccinationKeyToUpdate = keyMap[virus];
        vaccinations[id][vaccinationKeyToUpdate] = answer;
      }

      if (virus === Vaccine.FLU) {
        vaccinations[id].flu_season = getCurrentFluSeason();
        if (!answer) {
          vaccinations[id].received_flu_vaccine = VaccinationValue.UNKNOWN;
        }
      }

      if (virus === Vaccine.COVID) {
        vaccinations[id].covid_season = getCovidVaccineYear();
      }

      return vaccinations;
    },
    {}
  );
};

const isNotSkipped = (memberHealthReportStatus: HealthStatus): boolean => {
  return memberHealthReportStatus !== HealthStatus.SKIP;
};

const isNotVaccinated = (vaccinations: Vaccinations): boolean => {
  return (
    !isVaccinatedForFlu(vaccinations) || !isVaccinatedForCovid(vaccinations)
  );
};

export const isSomeoneUnvaccinated = (
  account: Account,
  householdMembersReports: HouseholdMembersHealthReport
): boolean => {
  const isHeadOfHouseholdUnvaccinated = isNotVaccinated(account.vaccinations);

  const isAMemberUnvaccinated = account?.householdMembers?.some(member => {
    if (!member.isActive) {
      return false;
    }
    const healthStatus = householdMembersReports[member.id]?.healthStatus;
    return isNotSkipped(healthStatus) && isNotVaccinated(member.vaccinations);
  });

  return isHeadOfHouseholdUnvaccinated || isAMemberUnvaccinated;
};
