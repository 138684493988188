import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Control, ArrayField } from 'react-hook-form';
import { Vaccine } from '~/context/global/user/account';
import {
  getFluVaccineCutoffYear,
  getCovidVaccineYear,
} from '~/common/vaccinations';
import { FluVaccineSelect } from '~/components/forms/fields/FluVaccineSelect';
import { CovidVaccineSelect } from '~/components/forms/fields/CovidVaccineSelect';
import * as Styled from './styles';

interface Props {
  formControl: Control;
  fields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  vaccine: Vaccine;
}

export const MemberVaccinationSelectList: React.FC<Props> = ({
  formControl,
  vaccine,
  fields,
}) => {
  const { t } = useTranslation();
  const fluVaccineCutoffYear = getFluVaccineCutoffYear();
  const covidVaccineYear = getCovidVaccineYear();

  const translationMap = {
    [Vaccine.FLU]: 'fluVaccine',
    [Vaccine.COVID]: 'covidVaccine',
  };

  return (
    <>
      <Grid item>
        <Styled.QuestionLabel>
          {t(`FormField.${translationMap[vaccine]}.label`, {
            year:
              vaccine === Vaccine.FLU ? fluVaccineCutoffYear : covidVaccineYear,
            context: 'household',
          })}
        </Styled.QuestionLabel>
      </Grid>
      {fields.map(field => {
        const props = {
          formControl,
          name: `${field.memberID}_${vaccine}`,
          'data-cy': `${vaccine}-select`,
          thirdPerson: parseInt(field.memberID) !== -1,
        };

        return (
          <Grid
            container
            direction="row"
            alignItems="center"
            key={field.memberID}
          >
            <Grid item xs={3}>
              <Styled.MemberName>{field.memberName}</Styled.MemberName>
              {field.memberDateOfBirth && (
                <Styled.MemberDateOfBirth>
                  {field.memberDateOfBirth}
                </Styled.MemberDateOfBirth>
              )}
            </Grid>
            <Grid item xs={9}>
              {vaccine === Vaccine.FLU && <FluVaccineSelect {...props} />}
              {vaccine === Vaccine.COVID && <CovidVaccineSelect {...props} />}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
