import { useEffect } from 'react';
import { useGlobalState } from '~/context/global';
import { HealthStatus } from '~/context/global/reports/householdReport';
import { HouseholdMember, Vaccine } from '~/context/global/user/account';
import { isSomeoneUnvaccinated } from './helpers';
import { isEmpty } from 'lodash';
import { isVaccinated, VaccinationValue } from '~/common/vaccinations';
import {
  getActiveHouseholdMembers,
  getDuplicateHouseholdNames,
} from '~/common/generalUtils';
import {
  useForm,
  useFieldArray,
  UseFormMethods,
  ArrayField,
} from 'react-hook-form';

interface HouseholdVaccinationField {
  memberID: number;
  memberName: string;
  memberDateOfBirth?: string;
}

export interface HouseholdVaccinationForm {
  receivedFluVaccine: HouseholdVaccinationField[];
  receivedCovidVaccine: HouseholdVaccinationField[];
}

interface UseHouseholdVaccinationForm {
  form: UseFormMethods<HouseholdVaccinationForm>;
  fluVaccineFields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  covidVaccineFields: Partial<ArrayField<Record<string, string>, 'id'>>[];
  someoneIsUnvaccinated: boolean;
}

export interface VaccinationFormValues {
  [key: string]: VaccinationValue;
}

export const useHouseholdVaccinationForm = (): UseHouseholdVaccinationForm => {
  const { account } = useGlobalState().user;
  const { householdMembers } = useGlobalState().healthReport.household;
  const form = useForm<HouseholdVaccinationForm>({ mode: 'onSubmit' });

  const someoneIsUnvaccinated = isSomeoneUnvaccinated(
    account,
    householdMembers
  );

  const { fields: covidVaccineFields } = useFieldArray({
    control: form.control,
    name: 'receivedCovidVaccine',
  });

  const { fields: fluVaccineFields } = useFieldArray({
    control: form.control,
    name: 'receivedFluVaccine',
  });

  const getVaccineArrayFields = (vaccine: Vaccine) => {
    const activeHouseholdMembers: HouseholdMember[] = getActiveHouseholdMembers(
      account.householdMembers
    );

    const duplicateHouseholdNames = getDuplicateHouseholdNames(
      activeHouseholdMembers
    );

    const fields = [];

    if (!isVaccinated(vaccine, account.vaccinations)) {
      fields.push({
        memberID: -1,
        memberName: account.nickname + ' (you)',
      });
    }

    fields.push(
      ...activeHouseholdMembers
        .filter(({ id, vaccinations }) => {
          const memberReport = householdMembers[id];
          if (memberReport.healthStatus === HealthStatus.SKIP) return false;
          const vaccinated = isVaccinated(vaccine, vaccinations);
          return !vaccinated;
        })
        .map(m => ({
          memberID: m.id,
          memberName: m.name,
          memberDateOfBirth: duplicateHouseholdNames.has(m.name)
            ? m.dateOfBirth
            : '',
        }))
    );

    return fields;
  };

  useEffect(() => {
    if (account?.householdMembers?.length && !isEmpty(householdMembers)) {
      if (!form.getValues()?.receivedCovidVaccine?.length) {
        const covidFields = getVaccineArrayFields(Vaccine.COVID);
        form.setValue('receivedCovidVaccine', covidFields);
      }

      if (!form.getValues()?.receivedFluVaccine?.length) {
        const fluFields = getVaccineArrayFields(Vaccine.FLU);
        form.setValue('receivedFluVaccine', fluFields);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.householdMembers, householdMembers]);

  return {
    form,
    fluVaccineFields,
    covidVaccineFields,
    someoneIsUnvaccinated,
  };
};
